<template>
  <v-dialog v-model="innerValue" transition="slide-y-transition" max-width="680">
    <validation-observer slim ref="observer" v-slot="{ handleSubmit }">
      <v-card>
        <v-card-title class="grey lighten-4">
          <app-id-icon />
          <span class="body-1">
            {{ $t("time.calendar.multiple_shift_deleting") }}
          </span>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="tertiary" icon @click="innerValue = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("buttons.close") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <date-picker v-model="beginDate" :max="endDate" :label="$t('global.beginDate')" :clearable="false" />
              </v-col>
              <v-col cols="12" sm="6">
                <date-picker v-model="endDate" :min="beginDate" :label="$t('global.endDate')" :clearable="false" />
              </v-col>
              <v-col cols="12">
                <validation-provider slim :name="$t('global.employees')" rules="required" v-slot="{ errors }">
                  <employee-picker v-model="selectedEmployees" :items="employees" multiple :error-messages="errors" />
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="loading" color="primary" depressed @click="handleSubmit(onDeleteBtnClick)">
            {{ $t("buttons.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  export default {
    name: "MultipleShiftDeleteDialog",
    components: {
      EmployeePicker: () => import("./EmployeePicker")
    },
    props: {
      value: Boolean,
      date: String,
      employees: Array
    },
    data: vm => ({
      beginDate: null,
      endDate: null,
      selectedEmployees: [],
      loading: false
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    },
    watch: {
      innerValue(val) {
        if (val) {
          this.beginDate = this.$moment(this.date)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.endDate = this.$moment(this.date)
            .endOf("month")
            .format("YYYY-MM-DD");
          this.resetState();
        }
      }
    },
    methods: {
      onDeleteBtnClick() {
        this.loading = true;
        const payload = {
          beginDate: this.beginDate,
          endDate: this.endDate,
          employees: this.selectedEmployees
        };
        this.$api.workplanService
          .deleteAllWorkplans(payload)
          .then(({ data }) => {
            if (!data.error) {
              this.innerValue = false;
              this.$emit("delete:workplan");
            }
          })
          .catch(console.error)
          .finally(() => (this.loading = false));
      },
      resetState() {
        if (this.$refs.observer) {
          this.$refs.observer.reset();
        }
        this.selectedEmployees = [];
      }
    }
  };
</script>
